import React from "react";
import { IMAGE } from "../../common/Theme";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ConcernLoader = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="concerngenerateloader">
      <div className="concerngenerateloader_middle">
        <Slider {...settings}>
          <div className="slider-animate-mid">
            <img src={IMAGE.gif_1} alt="" />
            <h3>Fantastic!</h3>
            <h5>
              You’re one step closer to your dream job.<br></br> We’re working
              backstage to generate <br></br> tailored content just for you
            </h5>
          </div>
          <div className="slider-animate-mid">
            <img src={IMAGE.gif_2} alt="" />
            <h3>Fantastic!</h3>
            <h5>
              You’re one step closer to your dream job.<br></br> We’re working
              backstage to generate <br></br> tailored content just for you
            </h5>
          </div>
          <div className="slider-animate-mid">
            <img src={IMAGE.gif_3} alt="" />
            <h3>Fantastic!</h3>
            <h5>
              You’re one step closer to your dream job.<br></br> We’re working
              backstage to generate <br></br> tailored content just for you
            </h5>
          </div>
          <div className="slider-animate-mid">
            <img src={IMAGE.gif_4} alt="" />
            <h3>Fantastic!</h3>
            <h5>
              You’re one step closer to your dream job.<br></br> We’re working
              backstage to generate <br></br> tailored content just for you
            </h5>
          </div>
          <div className="slider-animate-mid">
            <img src={IMAGE.gif_5} alt="" />
            <h3>Fantastic!</h3>
            <h5>
              You’re one step closer to your dream job.<br></br> We’re working
              backstage to generate <br></br> tailored content just for you
            </h5>
          </div>
          <div className="slider-animate-mid">
            <img src={IMAGE.gif_6} alt="" />
            <h3>Fantastic!</h3>
            <h5>
              You’re one step closer to your dream job.<br></br> We’re working
              backstage to generate <br></br> tailored content just for you
            </h5>
          </div>
          <div className="slider-animate-mid">
            <img src={IMAGE.gif_7} alt="" />
            <h3>Fantastic!</h3>
            <h5>
              You’re one step closer to your dream job.<br></br> We’re working
              backstage to generate <br></br> tailored content just for you
            </h5>
          </div>
          <div className="slider-animate-mid">
            <img src={IMAGE.gif_8} alt="" />
            <h3>Fantastic!</h3>
            <h5>
              You’re one step closer to your dream job.<br></br> We’re working
              backstage to generate <br></br> tailored content just for you
            </h5>
          </div>
        </Slider>
      </div>
      {/* <div className='concerngenerateloader_top'>
            <div className='concerngenerateloader_box'>
                <div className='default_loader_skelton card__skeleton'></div>
                <div className='default_loader_skelton card__skeleton'></div>
                <div className='default_loader_skelton half card__skeleton'></div>
            </div>
        </div> */}
    </div>
  );
};

export default ConcernLoader;

import React from "react";
import { IMAGE } from "./Theme";

const Loader = ({ title }) => {
  return (
    <div className="loader">
      {/* <div className="loader-top">
      <h4>
        <img src={IMAGE.union_icon} alt=''/> {title}
      </h4>
    </div> */}

      <div className="common-loaderStyle">
        <ul>
          <li>
            <div className="loaderone-right">
              <div className="card__skeleton card__title"></div>
            </div>
          </li>
          <li>
            <div className="loaderone-right">
              <div className="card__skeleton card__title"></div>
            </div>
          </li>
          <li>
            <div className="loaderone-right">
              <div className="card__skeleton card__title"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Loader;

import React from "react";

const ListLoader = () => {
  return (
    <div className="common-loaderStyle">
      <ul>
        <li>
          <div className="loaderone-right">
            <div className="card__skeleton card__title"></div>
          </div>
        </li>
        <li>
          <div className="loaderone-right">
            <div className="card__skeleton card__title"></div>
          </div>
        </li>
        <li>
          <div className="loaderone-right">
            <div className="card__skeleton card__title"></div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ListLoader;

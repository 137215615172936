import React from "react";
import { IMAGE } from "./Theme";

const NoDataListing = () => {
  return (
    <div className="nodata-list">
      <img src={IMAGE.no_data} alt="No data..." />
      <h4 className="mt-3">No Subscription </h4>
    </div>
  );
};

export default NoDataListing;

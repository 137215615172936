import React, { useRef, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { Mixpanel } from "../auth/YsfMixPanel";
import { IMAGE } from "../common/Theme";
import ApiConnection from "./ApiConnection";

const Header = (_referralCode) => {
  const stripeAll = useStripe();
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(0);

  const dropdownRef = useRef(null);
  const fetchReducer = useSelector((state) => state.dataflowreducer);
  var user_name = localStorage.getItem("fname");
  var user_email = localStorage.getItem("ysf_email");
  if (user_name) {
    user_name =
      user_name.slice(0, 1).toUpperCase() +
      user_name.slice(1, user_name.length);
  }

  const [language, setLanguage] = useState({ img: IMAGE.UK, lan: "English" });
  var jobAppLang = localStorage.getItem("jobAppLang");
  const isProd = process.env.REACT_APP_IS_PROD;

  const [daysLeft, setDaysLeft] = useState(0);
  const [license, setLicense] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [JobApp_ReferId, setJobApp_ReferId] = useState("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [unLockRewardsPoint, setUnLockRewardsPoint] = useState(0);
  const [unLockRewardId, setUnLockRewardId] = useState("");
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const [pageArray] = useState([
    "onboard",
    "home",
    "step-home",
    "concern",
    "Subscription",
    "success",
    "cancel",
    "Expired",
    "CancelSubscription",
    "ConfirmCancelSubscription",
    "Unsubscribe",
    "Cancelconfirm",
    "Invoices",
    "Invite",
    "Gift",
    "Setting",
    "Redeem",
    "Billing",
    "Refer",
    "Prepare-for-interview",
    "Update-the-CV",
  ]);
  const addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    // Format the date using toLocaleDateString
    return date.toLocaleDateString("en-US", {
      day: "2-digit", // 'numeric' for single digit days
      month: "long", // Use 'short' for abbreviated month (e.g., "Sep")
      year: "numeric",
    });
  }

  useEffect(() => {
    const GetUserdata = async () => {
      let getUserDataObj = {
        UserName: user_name,
        UserEmail: user_email,
      };
      const response = await ApiConnection.post(
        "/JobbsprangetUser/RegisterUser",
        getUserDataObj
      );
      if (response.data.isSuccess) {
        setDaysLeft(parseInt(response.data.licenseLeftdays));
        setLicense(response.data.license);
        setSubscriptionId(response.data.subscriptionId);
        setSubscriptionStatus(response.data.trialSubEnd);
        localStorage.setItem(
          "DaysLeft",
          parseInt(response.data.licenseLeftdays)
        );
        localStorage.setItem("license", response.data.license);
        localStorage.setItem("subscriptionId", response.data.subscriptionId);
        localStorage.setItem("JobApp_UserId", response.data.userId);
        localStorage.setItem(
          "JobApp_SubscriptionType",
          response.data.subscriptionType
        );
        // const currentDate = new Date();
        // const updatedDate = addDays(
        //   currentDate,
        //   parseInt(response.data.licenseLeftdays)
        // );
        //setSubscriptionEndDate(formatDate(updatedDate));
        if (response.data.subscriptionEndDate !== null) {
          localStorage.setItem(
            "JobApp_subscriptionEndDate",
            response.data.subscriptionEndDate
          );
          setSubscriptionEndDate(formatDate(response.data.subscriptionEndDate));
        } else {
          setSubscriptionEndDate("");
          localStorage.setItem("JobApp_subscriptionEndDate", "");
        }

        if (response.data.isSubscribe !== null) {
          localStorage.setItem("JobApp_IsSubscribe", response.data.isSubscribe);
          setIsSubscribe(response.data.isSubscribe);
        } else {
          setIsSubscribe("");
          localStorage.setItem("JobApp_IsSubscribe", "");
        }
      }
    };
    GetUserdata();
    if (sessionStorage.getItem("JobApp_ReferId")) {
      setJobApp_ReferId(sessionStorage.getItem("JobApp_ReferId"));
    }
  }, [user_name, user_email, JobApp_ReferId]);

  // Check Unlock Rewards Point
  useEffect(() => {
    const GetUnLockRewardsPoint = async () => {
      let getunlockRewardPointObj = {
        UserEmail: user_email,
      };
      const response = await ApiConnection.post(
        "/api/JobbsprangetPayment/GetUnlockRewards",
        getunlockRewardPointObj
      );
      if (response.data.isSuccess) {
        setUnLockRewardsPoint(parseInt(response.data.noOfUnlockRewardPoint));
        setUnLockRewardId(response.data.unlockRewardId);
      } else {
        setUnLockRewardsPoint(0);
        setUnLockRewardId("");
      }
    };
    GetUnLockRewardsPoint();
  }, [user_email]);

  useEffect(() => {
    switch (jobAppLang) {
      case "English":
        handleLanguageChange(IMAGE.UK, "English");
        break;
      case "Italian":
        handleLanguageChange(IMAGE.Italy, "Italian");
        break;
      case "Swedish":
        handleLanguageChange(IMAGE.Sweden, "Swedish");
        break;
      default:
    }
  }, [jobAppLang]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!JobApp_ReferId) {
      let pageName = window.location.pathname.split("/").pop();
      if (pageName && !pageArray.includes(pageName)) {
        let JobApp_ReferCoupon = window.location.pathname.slice(1);
        CouponValidation(JobApp_ReferCoupon);
      }
    }
  }, [JobApp_ReferId, pageArray]);

  const LogoutHandle = () => {
    try {
      localStorage.removeItem("email_verified");
      localStorage.removeItem("ysf_email");
      localStorage.removeItem("ysf_user");
      localStorage.removeItem("ysf_name");
      localStorage.removeItem("fname");
      localStorage.removeItem("DaysLeft");
      localStorage.removeItem("license");
      localStorage.removeItem("JobApp_SubscriptionType");
      localStorage.removeItem("JobApp_UserId");
      sessionStorage.removeItem("JobApp_ReferId");
      localStorage.removeItem("JobappUserId");
      localStorage.removeItem("JobApp_subscriptionEndDate");
      localStorage.removeItem("JobApp_IsSubscribe");
      Mixpanel.track("YsfJobApp:Logout");
      window.location.reload();
    } catch (error) {
      console.log("Error");
    }
  };

  const handleLanguageChange = (img, lan) => {
    const selectedLanguage = { img, lan };
    setLanguage(selectedLanguage);
    localStorage.setItem("jobAppLang", selectedLanguage.lan);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleReferralUserPayment = async () => {
    if (!stripeAll) {
      return;
    }

    let _paymentType = 2;
    let _priceId = "";
    let _coupon = JobApp_ReferId;
    console.log("userEmail" + user_email);
    console.log("_priceId" + _priceId);
    console.log("_paymentType" + _paymentType);
    console.log("_coupon#" + _coupon);
    try {
      // Check Coupon Validation
      if (_coupon) {
        let getfreeTrialCouponObj = {
          Couponcode: _coupon,
          UserEmail: user_email,
        };
        const response = await ApiConnection.post(
          "/JobbsprangetCoupon/FreeTrialCouponValidation",
          getfreeTrialCouponObj
        );
        if (response.data?.isSuccess) {
          Mixpanel.track(`YsfJobApp:FreeTrial Subscription for:${_coupon}`);
          sessionStorage.removeItem("JobApp_ReferId");
          setConfirmModalShow(true);
          return;
        }
      }

      if (isProd === "N") _priceId = "price_1PQPALJEaxc25mG4LKxN6Fxa";
      else _priceId = "price_1PXjoaJEaxc25mG43Y1T9b9T";
      let paymentRequest = {
        PriceId: _priceId,
        Quantity: 1,
        CouponId: _coupon,
        PaymentType: _paymentType,
        UserEmail: user_email,
        SuccessGift: 0,
      };
      const {
        data: { id, email },
      } = await ApiConnection.post(
        "/api/JobbsprangetPayment/create-checkout-session",
        paymentRequest
      );
      const { error } = await stripeAll.redirectToCheckout({
        sessionId: id,
      });
      if (error) {
        console.error("Stripe Checkout error:", error);
      } else {
        localStorage.removeItem("JobApp_ReferId");
        Mixpanel.track(`YsfJobApp:PayNow: '4 Weeks #' ${email} '#'${_coupon}`);
      }
    } catch (ex) {
      console.log("Error in subscription!!");
    }
  };
  const handleUnlockRewardPayment = async () => {
    if (!stripeAll) {
      return;
    }
    let _paymentType = 2;
    let _priceId = "";
    let _coupon = JobApp_ReferId;
    console.log("userEmail" + user_email);
    console.log("_priceId" + _priceId);
    console.log("_paymentType" + _paymentType);
    console.log("_coupon#" + _coupon);
    try {
      if (isProd === "N") _priceId = "price_1PQPALJEaxc25mG4LKxN6Fxa";
      else _priceId = "price_1PXjoaJEaxc25mG43Y1T9b9T";

      let paymentRequest = {
        PriceId: _priceId,
        Quantity: 1,
        CouponId: "",
        PaymentType: _paymentType,
        UserEmail: user_email,
        SuccessGift: 0,
        NoOfUnlockRewardPoint: parseInt(unLockRewardsPoint),
        UnlockRewardId: unLockRewardId,
      };
      const {
        data: { id, email },
      } = await ApiConnection.post(
        "/api/JobbsprangetPayment/create-checkout-session",
        paymentRequest
      );
      const { error } = await stripeAll.redirectToCheckout({
        sessionId: id,
      });
      if (error) {
        console.error("Stripe Checkout error:", error);
      } else {
        sessionStorage.removeItem("JobApp_ReferId");
        Mixpanel.track(`YsfJobApp:PayNow: '4 Weeks #' ${email} '#'${_coupon}`);
      }
    } catch (ex) {
      console.log("Error in subscription!!");
    }
  };
  const CouponValidation = async (_JobApp_ReferId) => {
    let validateCoupon = {
      Couponcode: _JobApp_ReferId.toUpperCase(),
      CouponPlan: "2",
      UserEmail: user_email,
    };

    const response = await ApiConnection.post(
      "/JobbsprangetCoupon/CouponValidation",
      validateCoupon
    );
    if (response.data.isSuccess) {
      sessionStorage.setItem("JobApp_ReferId", _JobApp_ReferId);
    } else {
      sessionStorage.removeItem("JobApp_ReferId");
      setJobApp_ReferId("");
    }
  };
  const hndFreeTrial = async (value) => {
    sessionStorage.removeItem("JobApp_ReferId");
    setConfirmModalShow(false);
    window.location.reload();
  };

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row align-items-center">
          {license === "Expired" ? (
            <div className="col-lg-2 col-5">
              <NavLink to="/">
                <img src={IMAGE.logo_svg} alt="logo" className="logo" />
              </NavLink>
            </div>
          ) : (
            <div className="col-lg-2 col-5">
              <NavLink to="/">
                <img src={IMAGE.logo_svg} alt="logo" className="logo" />
              </NavLink>
            </div>
          )}

          <div className="col-lg-10 col-7">
            <div className="header-right">
              <div id="userInfo" className="header-right">
                <label>{fetchReducer?.title}</label>
                <NavLink to="/Refer">
                  <img
                    src={IMAGE.gift_icon}
                    title="Refer friend's"
                    alt="Refer friend's"
                  />
                </NavLink>
                <div className="languages-container">
                  <div
                    className="header-dropdown language-dropdown"
                    ref={dropdownRef}
                  >
                    <button
                      onClick={toggleDropdown}
                      className="dropdown-toggle"
                    >
                      <span>
                        <img
                          src={language.img}
                          alt={language.lan}
                          title={language.lan}
                        />
                      </span>
                    </button>
                    {isOpen && (
                      <div className="dropdown-menu">
                        <ul>
                          <li>
                            <h5 id="Lang_H5ChangeLang">
                              CHANGE OUTPUT LANGUAGE
                            </h5>
                          </li>
                          <li
                            className={
                              language.lan === "English" ? "active" : ""
                            }
                            onClick={() =>
                              handleLanguageChange(IMAGE.UK, "English")
                            }
                          >
                            <img src={IMAGE.UK} title="English" alt="English" />{" "}
                            English
                          </li>
                          <li
                            className={
                              language.lan === "Italian" ? "active" : ""
                            }
                            onClick={() =>
                              handleLanguageChange(IMAGE.Italy, "Italian")
                            }
                          >
                            <img
                              src={IMAGE.Italy}
                              title="Italian"
                              alt="Italian"
                            />{" "}
                            Italian
                          </li>
                          <li
                            className={
                              language.lan === "Swedish" ? "active" : ""
                            }
                            onClick={() =>
                              handleLanguageChange(IMAGE.Sweden, "Swedish")
                            }
                          >
                            <img
                              src={IMAGE.Sweden}
                              title="Swedish"
                              alt="Swedish"
                            />{" "}
                            Swedish
                          </li>
                        </ul>
                        <div className="l-note">
                          <h5 id="Lang_H5Note">PLEASE NOTE</h5>
                          <p id="Lang_paraChanges">
                            Changing the output language won't change the
                            interface language.
                          </p>
                          <div className="language-information">
                            <h4>Steps to change the output language:</h4>
                            <ul>
                              <li>Select your desired language.</li>
                              <li>
                                Click the "Generate" button to apply the change.
                              </li>
                            </ul>
                          </div>
                          <div className="language-information">
                            <h4>Troubleshooting:</h4>
                            <ul>
                              <li>
                                Results looking strange? Simply click "Generate"
                                to fix it up.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Dropdown className="header-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    <span>{user_name?.substring(0, 2)}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dopdown-top">
                      <h5>{user_email}</h5>
                      <span>{user_name?.substring(0, 2)}</span>
                    </div>
                    <ul>
                      <li>
                        <Dropdown.Item>
                          <NavLink to="/Subscription">Subscription</NavLink>
                        </Dropdown.Item>
                      </li>
                      <li>
                        <a
                          href="https://www.yourspeechfactory.com/support"
                          alt="Support"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Support
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.yourspeechfactory.com/about"
                          alt="about"
                          rel="noreferrer"
                          target="_blank"
                        >
                          About(1.0.4.0)
                        </a>
                      </li>
                      <li>
                        <button
                          className="signOutBtn"
                          onClick={() => setShow(true)}
                        >
                          Sign Out
                        </button>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>

                <NavLink to="/" className="homeBtn">
                  <img src={IMAGE.home_icon} alt="icon" title="Home" />
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-12">
            <h5 className="subscriptionText text-center">
              {(() => {
                const _daysLeft = parseInt(localStorage.getItem("DaysLeft"));
                //console.log("_daysLeft", _daysLeft);

                const subscriptionEndDate = localStorage.getItem(
                  "JobApp_subscriptionEndDate"
                );
                //console.log("subscriptionEndDate" + subscriptionEndDate);
                //console.log("subscriptionEndDate#" + subscriptionEndDate.length);
                const subscriptionType = parseInt(
                  localStorage.getItem("JobApp_SubscriptionType")
                );
                const jobApp_IsSubscribe =
                  localStorage.getItem("JobApp_IsSubscribe");
                //console.log("jobApp_IsSubscribe", jobApp_IsSubscribe);
                // Free Trial Section
                if (
                  parseInt(jobApp_IsSubscribe) === 0 &&
                  subscriptionEndDate.length === 0
                ) {
                  if (_daysLeft < 0) {
                    return (
                      <>
                        {`Your free trial expired.`}
                        {"  "}
                        {subscriptionType === 0 && (
                          <NavLink to="/Subscription"> See Plans </NavLink>
                        )}
                      </>
                    );
                  } else if (_daysLeft === 0) {
                    return (
                      <>
                        {`Your free trial expires today.`}
                        {"  "}
                        {subscriptionType === 0 && (
                          <NavLink to="/Subscription"> See Plans </NavLink>
                        )}
                      </>
                    );
                  } else if (_daysLeft < 7) {
                    return (
                      <>
                        {`You have ${_daysLeft} days left on your free trial.`}
                        {"  "}
                        {subscriptionType === 0 && (
                          <NavLink to="/Subscription"> See Plans </NavLink>
                        )}
                      </>
                    );
                  } else {
                    return "";
                  }
                }
                // Subscription Section
                else {
                  if (jobApp_IsSubscribe === 0 && _daysLeft === 0) {
                    return (
                      <>
                        {`Your subscription ends today.`}
                        {"  "}
                        {<NavLink to="/Subscription"> See Plans </NavLink>}
                      </>
                    );
                  } else if (jobApp_IsSubscribe === 0 && _daysLeft < 0) {
                    return (
                      <>
                        {`Your subscription expired.`}
                        {"  "}
                        {<NavLink to="/Subscription"> See Plans </NavLink>}
                      </>
                    );
                  } else if (jobApp_IsSubscribe === 0 && _daysLeft < 7) {
                    return (
                      <>
                        {`Your subscription will end on ${subscriptionEndDate}.`}
                        {"  "}
                        {<NavLink to="/Subscription"> See Plans </NavLink>}
                      </>
                    );
                  }
                }
              })()}

              {/* Redeem and Unlock buttons */}
              {JobApp_ReferId && (
                <button
                  className="flashbttn"
                  onClick={handleReferralUserPayment}
                >
                  Redeem Code
                </button>
              )}
              {JobApp_ReferId === "" && parseInt(unLockRewardsPoint) > 0 && (
                <button
                  className="flashbttn"
                  onClick={handleUnlockRewardPayment}
                >
                  Unlock reward
                </button>
              )}
            </h5>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="logout-modal text-center p-5">
          <h4>Are you sure to logout? </h4>
          <ul>
            <li>
              <button className="btn  btn-primary px-4" onClick={LogoutHandle}>
                Yes
              </button>
            </li>
            <li>
              <button
                className="btn  btn-outline-danger px-4"
                onClick={() => setShow(false)}
              >
                No
              </button>
            </li>
          </ul>
          <button className="close_Icon" onClick={() => setShow(false)}>
            <img src={IMAGE.close_icon} alt="" />
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={confirmModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="subscription-modal language_setup_modal"
        centered
      >
        <Modal.Body>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={IMAGE.success_img}
                className="w-100"
                alt="success_img"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="confirm-modal-content"
                style={{ paddingLeft: "30px" }}
              >
                <h4>Congratulations!</h4>
                <p>
                  You're activating your free package! Your subscription will
                  renew, and soon you can invite friends for extended premium
                  membership. Awesome!
                </p>
                <div className="d-flex mt-4">
                  <button
                    className="BtnStyle_two mx-2"
                    onClick={() => hndFreeTrial(false)}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Header;

import React from 'react'

const PageLoader = () => {
  return (
    <div className="loader pageloader">

      <div class="common-loaderStyle">
        <ul>
          <li>
            <div class="loaderone-right">
              <div class="card__skeleton card__title"></div>
            </div>
          </li>
          <li>
            <div class="loaderone-right">
              <div class="card__skeleton card__title"></div>
            </div>
          </li>
          <li>
            <div class="loaderone-right">
              <div class="card__skeleton card__title"></div>
            </div>
          </li>
        </ul>
      </div>
  
  </div>
  )
}

export default PageLoader
import React, { useEffect, useRef, useState } from "react";
import { IMAGE } from "../../common/Theme";
import { FileUploader } from "react-drag-drop-files";
import Nodata from "../../common/Nodata";
import Loader from "../../common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  StepAction,
  JobTitleAction,
  JobpostUrlAction,
  JobPostIdAction,
  JobPostCVName,
} from "../../reducer/Dataflowreducer";
import Modal from "react-bootstrap/Modal";
import ApiConnection from "../../common/ApiConnection";
import FormdataApi from "../../common/FormdataApi";
import { NavLink } from "react-router-dom";
import ConcernLoader from "../concern_gain/ConcernLoader";
import { Mixpanel } from "../../auth/YsfMixPanel";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
const JobProfile = () => {
  const [file, setFile] = useState(null);
  const [isfile, setisfile] = useState("");
  const [fileName, setFileName] = useState("");
  const [list, setlist] = useState([]);
  const [rowId, setrowId] = useState(null);
  const [showmodal, setshowmodal] = useState(false);
  const [cvTxt, setCVTxt] = useState("");
  const [linkdinUrl, setLinkdinUrl] = useState("");
  const [enablegenateBtn, setenablegenateBtn] = useState(false);
  const [isactivecvfield, setisactivecvfield] = useState(true);
  const [isactiveurlfield, setisactiveurlfield] = useState(true);
  const [loading, setloading] = useState(false);
  const [nodata, setnodata] = useState(true);
  const [output, setoutput] = useState(false);
  const [uploadLoader, setuploadLoader] = useState(false);
  const [isShowGenerateBtn, setIsShowGenerateBtn] = useState(false);
  const [activeTab, setactiveTab] = useState("cv");
  const [bodytext, setbodytext] = useState("");
  const [introtext, setintrotext] = useState("");
  const [conclusionTxt, setConclusionTxt] = useState("");

  const [isCoverletterloading, setIsCoverletterloading] = useState(true);
  const [jobPostId, setJobPostId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobPostUrl, setJobPostUrl] = useState("");
  const [goToConcern, setGoToConcern] = useState("");
  const [isShowSaveFinishBtn, setIsShowSaveFinishBtn] = useState(false);
  const fetchReducer = useSelector((state) => state.dataflowreducer);
  const [getError, setGetError] = useState(false);
  const [linkedinError, setLinkedinError] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const [iskeywordShow, setiskeywordShow] = useState(false);
  const [currentKewords, setcurrentKewords] = useState([]);
  const [completedKeywords, setcompletedKeywords] = useState([]);

  const [regenerateloader, setregenerateloader] = useState(false);
  const [righttoleftdrop, setrighttoleftdrop] = useState(false);
  const [lefttorightdrop, setlefttorightdrop] = useState(false);
  const [defaultdrop, setdefaultdrop] = useState(true);

  var user_email = localStorage.getItem("ysf_email");
  var user_name = localStorage.getItem("fname");
  var jobAppLang = localStorage.getItem("jobAppLang");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  var srNoregex = new RegExp("\\d+\\.", "g");
  var splitText = /\n\d+\.\s/;
  const IntroeditTextRef = useRef(null);
  const BodyeditTextRef = useRef(null);
  const ConclusioneditTextRef = useRef(null);

  useEffect(() => {
    try {
      if (fetchReducer?.jobInterviewPreparationDone) {
        GetUserFeedbackCoverletter();
        setFeedback(true);
      }
    } catch (error) {
      console.log("Error!!!");
    } finally {
      Mixpanel.identify(user_email);
      if (user_name) {
        Mixpanel.people.set({
          $first_name: user_name,
        });
      }
    }
  }, []);

  const GetUserFeedbackCoverletter = async () => {
    try {
      let feedbackCoverletterObj = {
        UserEmail: user_email,
        JobPostURL: fetchReducer?.url,
        JobPostId: fetchReducer?.jobpostId,
      };
      const feedBackCoverLetterResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGetCVCoverLetterFeedback",
        feedbackCoverletterObj
      );
      if (feedBackCoverLetterResponse.data?.isSuccess) {
        setnodata(false);
        setoutput(true);
        setGetError(false);
        var feedbackData = feedBackCoverLetterResponse.data?.jobPostFeedBack;
        populateCVFeedback(feedbackData);

        //CoverLetter
        var coverLetterResponseData =
          feedBackCoverLetterResponse.data?.userCVCoverLetter;
        populateCVCoverLetter(coverLetterResponseData);

        // Set CV OR LINKEDIN URL
        var cvName = feedBackCoverLetterResponse.data?.userCVName.toString();
        if (cvName) {
          setisfile(cvName);
          const jobPostCvName = getLastUploadedCVDate(cvName.toString());
          console.log("jobPostCvName#" + jobPostCvName.cvName);
          console.log("lastUploadedDate#" + jobPostCvName.lastUploadedDate);
          if (jobPostCvName.lastUploadedDate) {
            cvName =
              jobPostCvName.cvName +
              " (" +
              jobPostCvName.lastUploadedDate +
              ")";
          }
          setFileName(cvName);

          setGoToConcern(1);
          setIsShowGenerateBtn(true);
          setIsShowSaveFinishBtn(false);
        }
        var linkedinUrl = feedBackCoverLetterResponse.data?.userLinkedinUrl;
        if (linkedinUrl) {
          setLinkdinUrl(linkedinUrl);
          setisfile("");
          setFileName("");
        }
        setiskeywordShow(true);
        if (feedBackCoverLetterResponse.data?.jobPostMissingSkill) {
          let missingKeyWord =
            feedBackCoverLetterResponse.data?.jobPostMissingSkill;
          var splitMissingData = missingKeyWord?.split("|");
          console.log("splitMissingData" + splitMissingData);
          let mMissingArray = [];
          if (splitMissingData.length > 0) {
            splitMissingData.forEach((element) => {
              if (element) {
                mMissingArray.push(element);
              }
            });
            setcurrentKewords(mMissingArray);
          } else {
            setcurrentKewords(mMissingArray);
          }
        }

        if (feedBackCoverLetterResponse.data?.jobPostReviewedSkill) {
          let reviwedKeyword =
            feedBackCoverLetterResponse.data?.jobPostReviewedSkill;
          var splitReviwedData = reviwedKeyword?.split("|");
          console.log("splitReviwedData" + splitReviwedData);
          let mReviewedArray = [];
          if (splitReviwedData.length > 0) {
            splitReviwedData.forEach((element) => {
              if (element) {
                mReviewedArray.push(element);
              }
            });
            setcompletedKeywords(mReviewedArray);
          } else {
            setcompletedKeywords(mReviewedArray);
          }
        }
        if (
          !feedBackCoverLetterResponse.data?.jobPostMissingSkill &&
          !feedBackCoverLetterResponse.data?.jobPostReviewedSkill
        ) {
          let getMissingKeywordObj = {
            JobPostUserLinkedinURL: "",
            UserEmail: user_email,
            JobPostURL: fetchReducer?.url,
            JobPostUserCV: "Get",
            JobPostUserCVName: "fileName",
            JobPostId: fetchReducer?.jobpostId,
            Language: jobAppLang,
          };
          let missingKeywordsResponse = await ApiConnection.post(
            "/JobbsprangetUser/JobbsprangetUserMissingKeywordInCVLinkedinURL",
            getMissingKeywordObj
          );
          if (missingKeywordsResponse) {
            populateMissingKeywords(
              missingKeywordsResponse,
              fetchReducer?.jobpostId
            );
          }
        }
      }
    } catch (e) {
      console.log("Error in loadingdata");
    }
  };
  const getLastUploadedCVDate = (input) => {
    console.log("input#" + input);
    const match = input.match(/^(.+?)\s*\(.*\)\s*\(([^)]+)\)$/);
    if (match) {
      const cvName = match[1]; // Group 1: The name of the CV
      const lastUploadedDate = match[2];
      return { cvName, lastUploadedDate };
    }
    return input; // Return null if no match
  };

  const fileTypes = ["DOC", "PDF", "DOCX"];

  const CVUploadHandleChange = async (file) => {
    setFile(file);
    setIsShowGenerateBtn(false);
    if (file) {
      setuploadLoader(true);

      setisactiveurlfield(false);
      setenablegenateBtn(true);
      setFileName(file.name);

      try {
        let data = new FormData();
        data.append("files", file);
        const response = await FormdataApi.post(
          "/JobbsprangetFileUploadUser/ReadDocFile",
          data
        );
        if (response) {
          if (
            feedback ||
            fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
            fetchReducer.goToConceren === "CVFeedbackGenerated"
          ) {
            setGoToConcern(1);
          } else {
            setGoToConcern(2);
            setIsShowGenerateBtn(true);
          }
          setuploadLoader(false);
          setIsShowGenerateBtn(true);
          setCVTxt(response.data);
        }
      } catch (err) {
        setuploadLoader(false);
      }
    } else {
      setisactiveurlfield(true);
      setenablegenateBtn(false);
    }
  };
  const linkdinUrlHandleInput = (value) => {
    setLinkdinUrl(value);
    if (value === "") {
      setIsShowGenerateBtn(false);
      setisactivecvfield(true);
      setenablegenateBtn(false);
      setLinkedinError(false);
    } else {
      if (
        feedback ||
        fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
        fetchReducer.goToConceren === "CVFeedbackGenerated"
      ) {
        setGoToConcern(1);
      } else {
        setGoToConcern(2);
      }
      setIsShowGenerateBtn(true);
      setisactivecvfield(false);
      setenablegenateBtn(true);
    }
  };

  const DeleteHandle = () => {
    setisfile("");
    setisactiveurlfield(true);
    setenablegenateBtn(false);
    setIsShowGenerateBtn(false);
  };

  const NextAction = async (step) => {
    try {
      let cvSelectedFeedbackStr = "";
      var separator = "||";
      list.forEach((element) => {
        if (element.toggleSelected) {
          cvSelectedFeedbackStr += element.text + separator;
        }
      });

      let userLinkedinCVObj = {
        UserEmail: user_email,
        JobPostUserLinkedinURL: linkdinUrl,
        JobPostUserCV: "Finish",
        JobPostUserFeedBack: cvSelectedFeedbackStr,
        JobPostIsCV: linkdinUrl !== "" ? 0 : 1,
        JobPostURL: fetchReducer?.url,
        JobPostUserCVName: fileName,
        JobPostId: fetchReducer?.jobpostId,
      };

      const response = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetJobCVUploadApi",
        userLinkedinCVObj
      );

      if (response.data?.isSuccess) {
        dispatch(StepAction(step));
        navigate("/home");
      }
    } catch (err) {
      console.log("Error!");
    }
  };

  const SelectHandle = (row) => {
    const updateData = list.map((item) => {
      if (row.id !== item.id) {
        return item;
      }
      return {
        ...item,
        toggleSelected: !item.toggleSelected,
      };
    });

    setlist(updateData);
  };

  const ModalShow = (id) => {
    setrowId(id);
    setshowmodal(true);
  };

  const DlistHandle = () => {
    const newList = list.filter((item) => item.id !== rowId);
    setshowmodal(false);
    setlist(newList);
  };

  const BtnGenerateFeedbackHnd = async (isError, _cvTxt, _fileName) => {
    let cvLinkedinUrlFeedbackObj = "";
    let cvLinkedinUrlCoverLetterObj = "";
    jobAppLang = localStorage.getItem("jobAppLang");
    try {
      if (linkdinUrl.trim() !== "") {
        var myLinkedinUrlRegEx = new RegExp(
          /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm
        );
        myLinkedinUrlRegEx = new RegExp(
          /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/
        );

        if (!myLinkedinUrlRegEx.test(linkdinUrl.trim())) {
          setLinkedinError(true);
          return;
        }
        setLinkedinError(false);

        // Linkedin FEEDBACK Request
        cvLinkedinUrlFeedbackObj = {
          JobPostUserLinkedinURL: linkdinUrl.trim(),
          UserEmail: user_email,
          JobPostURL: fetchReducer?.url,
          JobPostUserCV: "",
          JobPostUserCVName: "",
          JobPostId: fetchReducer?.jobpostId,
          Language: jobAppLang,
        };
      } else {
        //CV FEEDBACK Request
        if (_cvTxt && _fileName) {
          console.log("_cvTxt" + _cvTxt);
          console.log("_fileName" + _fileName);
          //setiskeywordShow(false);
          let mArray = [];
          setcurrentKewords(mArray);
          setcompletedKeywords(mArray);

          cvLinkedinUrlFeedbackObj = {
            JobPostUserLinkedinURL: "",
            UserEmail: user_email,
            JobPostURL: fetchReducer?.url,
            JobPostUserCV: _cvTxt,
            JobPostUserCVName: _fileName,
            JobPostId: fetchReducer?.jobpostId,
            Language: jobAppLang,
          };
        } else {
          cvLinkedinUrlFeedbackObj = {
            JobPostUserLinkedinURL: "",
            UserEmail: user_email,
            JobPostURL: fetchReducer?.url,
            JobPostUserCV: cvTxt,
            JobPostUserCVName: fileName,
            JobPostId: fetchReducer?.jobpostId,
            Language: jobAppLang,
          };
        }
      }
      //COVER LETTER
      cvLinkedinUrlCoverLetterObj = {
        UserEmail: user_email,
        JobPostURL: fetchReducer?.url,
        JobPostId: fetchReducer?.jobpostId,
        Language: jobAppLang,
      };

      //Loading page
      setnodata(false);
      setloading(true);
      setoutput(false);
      setIsCoverletterloading(true);
      if (isError) {
        setGetError(false);
      }
      //console.log("cvLinkedinUrlFeedbackObj#"+JSON.stringify(cvLinkedinUrlFeedbackObj));
      // GET FEEDBACK RESPONSE
      const feedbackResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetUserLinkedinURL",
        cvLinkedinUrlFeedbackObj
      );
      var feedbackResponseData = feedbackResponse?.data;
      var isFeedbackError = feedbackResponseData.includes(
        "TimeOut Error: Try Again"
      );
      if (isFeedbackError) {
        setloading(false);
        setnodata(true);
        setGetError(true);
        setIsShowGenerateBtn(false);
        return;
      }
      populateCVFeedback(feedbackResponseData);

      // Mising Keywords
      setiskeywordShow(true);
      let missingKeywordsResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetUserMissingKeywordInCVLinkedinURL",
        cvLinkedinUrlFeedbackObj
      );
      if (missingKeywordsResponse) {
        populateMissingKeywords(
          missingKeywordsResponse,
          fetchReducer?.jobpostId
        );
      }

      //COVER LETTER RESPONSE
      const coverletterResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGenerateCoverletterApi",
        cvLinkedinUrlCoverLetterObj
      );
      //console.log("cvLinkedinUrlCoverLetterObj#"+JSON.stringify(cvLinkedinUrlCoverLetterObj));
      var coverLetterResponseData = coverletterResponse?.data?.jobPostReponse;
      var isCoverLetterError = coverLetterResponseData.includes(
        "TimeOut Error: Try Again"
      );
      isCoverLetterError = coverLetterResponseData.includes("Sure,");
      if (isCoverLetterError) {
        setnodata(true);
        setloading(false);
        setoutput(false);
        setGetError(true);
        setIsShowGenerateBtn(false);
        return;
      }
      populateCVCoverLetter(coverLetterResponseData);
    } catch (err) {
      console.log(err.response);
      setnodata(false);
      setloading(false);
      setoutput(false);
    } finally {
      Mixpanel.track("YsfJobApp:Generate CV Feedback");
    }
  };

  const populateCVFeedback = async (cvFeedbackResponse) => {
    try {
      //console.log("cvFeedbackResponse:"+cvFeedbackResponse);
      var splitFeedbackData;
      var feedbackTxt;
      let feedbackTempArray = [];
      splitFeedbackData = cvFeedbackResponse.split("\n\n");
      if (splitFeedbackData.length > 0) {
        splitFeedbackData = cvFeedbackResponse.split("\n\n");
        splitFeedbackData.splice(0, 2);
        splitFeedbackData &&
          splitFeedbackData.forEach((element, index) => {
            feedbackTxt = element.trim();
            if (feedbackTxt.length > 50) {
              feedbackTxt = feedbackTxt.replace(/\d+/g, "");
              feedbackTxt = feedbackTxt.replace(/\./g, "");
              feedbackTxt = feedbackTxt.replace(".", "");
              feedbackTxt = feedbackTxt.replace(":", "");
              feedbackTxt = removeAsterisks(feedbackTxt.trim());
              let _feedbackTxt = removeHashBeforeTxt(feedbackTxt);
              _feedbackTxt = removeHashesFromNewLines(_feedbackTxt);
              if (feedbackTxt.length > 80) {
                var feedbackObj = { id: index, text: _feedbackTxt.trim() };
                feedbackTempArray.push(feedbackObj);
              }
            }
          });
        setlist(feedbackTempArray);
      } else if (cvFeedbackResponse.split(srNoregex).length > 0) {
        splitFeedbackData = cvFeedbackResponse.split(srNoregex);
        splitFeedbackData.splice(0, 1);
        splitFeedbackData &&
          splitFeedbackData.forEach((element, index) => {
            feedbackTxt = element.trim();
            if (feedbackTxt.length > 50) {
              feedbackTxt = feedbackTxt.replace("**", "");
              feedbackTxt = feedbackTxt.replace("**", "");
              feedbackTxt = feedbackTxt.replace(".", "");
              feedbackTxt = feedbackTxt.replace(":", "");
              feedbackTxt = removeAsterisks(feedbackTxt.trim());
              let _feedbackTxt = removeHashBeforeTxt(feedbackTxt);
              _feedbackTxt = removeHashesFromNewLines(_feedbackTxt);
              var feedbackObj = { id: index, text: _feedbackTxt.trim() };
              feedbackTempArray.push(feedbackObj);
            }
          });
        setlist(feedbackTempArray);
      } else if (cvFeedbackResponse.split(splitText).length > 0) {
        splitFeedbackData = cvFeedbackResponse.split(splitText);
        splitFeedbackData.splice(0, 1);
        splitFeedbackData &&
          splitFeedbackData.forEach((element, index) => {
            feedbackTxt = element.trim();
            if (feedbackTxt.length > 50) {
              feedbackTxt = feedbackTxt.replace("**", "");
              feedbackTxt = feedbackTxt.replace("**", "");
              feedbackTxt = feedbackTxt.replace(".", "");
              feedbackTxt = feedbackTxt.replace(":", "");
              feedbackTxt = removeAsterisks(feedbackTxt.trim());
              let _feedbackTxt = removeHashBeforeTxt(feedbackTxt);
              _feedbackTxt = removeHashesFromNewLines(_feedbackTxt);
              var feedbackObj = { id: index, text: _feedbackTxt.trim() };
              feedbackTempArray.push(feedbackObj);
            }
          });
        setlist(feedbackTempArray);
        setIsShowSaveFinishBtn(false);
      }

      //console.log("feedbackTempArray_length#"+feedbackTempArray.length);
      if (feedbackTempArray.length === 0) {
        setGetError(true);
        setIsShowGenerateBtn(true);
      } else {
        setloading(false);
        setoutput(true);
        setIsShowSaveFinishBtn(false);
        let _tempArray = [];
        setlist(_tempArray);
        const newTempArray = feedbackTempArray.slice(
          0,
          feedbackTempArray.length - 1
        );
        setlist(newTempArray);
      }
    } catch (e) {
      console.log("feedback-error#");
    }
  };

  const populateCVCoverLetter = async (cvCoverLetterResponse) => {
    try {
      //console.log("coverLetterData#" + cvCoverLetterResponse);
      let introTxt;
      let bodyTxt;
      let conclusionTxt;
      var isCoverLetterError = cvCoverLetterResponse.includes(
        "TimeOut Error: Try Again"
      );
      isCoverLetterError = cvCoverLetterResponse.includes("Sure,");
      if (isCoverLetterError) {
        setnodata(true);
        setloading(false);
        setoutput(false);
        setGetError(true);
        setIsShowGenerateBtn(false);
        return;
      }
      var splitSections = splitMultipleLangText(cvCoverLetterResponse);
      if (splitSections) {
        //console.log(splitSections);
        introTxt = removeStars(splitSections["Introduction"]);
        bodyTxt = removeStars(splitSections["Body"]);
        conclusionTxt = removeStars(splitSections["Conclusion"]);
        setintrotext(removeColon(introTxt.trim()));
        setbodytext(removeColon(bodyTxt.trim()));
        setConclusionTxt(removeColon(conclusionTxt.trim()));
        setloading(false);
        setoutput(true);
        setenablegenateBtn(false);
        setIsCoverletterloading(false);
        setIsShowSaveFinishBtn(false);
      }
    } catch (e) {
      console.log("Error in Feedback Generation");
      setIsShowSaveFinishBtn(true);
    }
  };

  function splitMultipleLangText(text) {
    const sections = {};
    const parts = text.split(/(Introduction|Body|Conclusion)/);

    for (let i = 1; i < parts.length; i += 2) {
      const heading = parts[i].trim();
      const content = parts[i + 1].trim();
      sections[heading] = content;
    }
    return sections;
  }
  const removeStars = (input) => {
    return input.replace(/\*+/g, "").trim(); // Removes any number of '*' and trims the result
  };

  const BtnGoToConcernHnd = async () => {
    try {
      console.log("fetchReducer?.stepcount" + fetchReducer?.stepcount);
      console.log("jobTitleAction#" + fetchReducer?.title);
      console.log("jobpostIdAction#" + fetchReducer?.jobpostId);
      console.log("jobPostUrl#" + fetchReducer?.url);
      setJobTitle(fetchReducer?.title);
      setJobPostUrl(fetchReducer?.url);
      setJobPostId(fetchReducer?.jobpostId);
      dispatch(JobTitleAction(jobTitle));
      dispatch(JobpostUrlAction(jobPostUrl));
      dispatch(JobPostIdAction(jobPostId));
      dispatch(JobPostCVName(fileName));
      ReUploadCVHnd();
      // navigate("/concern", {
      //   state: {
      //     id: fetchReducer?.jobpostId,
      //     CompanyJobTitle: fetchReducer?.title,
      //     JobPostCVName: fileName,
      //   },
      navigate("/Prepare-for-interview", {
        state: {
          id: fetchReducer?.jobpostId,
          CompanyJobTitle: fetchReducer?.title,
          JobPostCVName: fileName,
        },
      });
    } catch (error) {
      console.log("Error in Good To Go!");
    } finally {
      Mixpanel.track("YsfJobApp:Go To Concern");
    }
  };

  const ReUploadCVHnd = async () => {
    let updateCV_Obj = {
      UserEmail: user_email,
      JobPostId: fetchReducer?.jobpostId,
      JobPostUserCVName: fileName,
      JobPostUserCV: cvTxt,
    };
    console.log("updateCV_Obj#" + updateCV_Obj);
    await ApiConnection.post(
      "/JobbsprangetUser/JobbsprangetReUploadCVApi",
      updateCV_Obj
    );
  };

  const CancelUploadHandle = async () => {
    setuploadLoader(false);
    setisfile("");
  };

  const ToCoverLetter = async () => {
    setactiveTab("cover");
    Mixpanel.track("YsfJobApp:To Cover Letter");
  };

  const removeColon = (strTxt) => {
    return strTxt.replace(":", "").trim();
  };
  const removeAsterisks = (strTxt) => {
    return strTxt.replace(/\*/g, "");
  };
  const removeHashBeforeTxt = (strTxt) => {
    return strTxt.replace(/(^#*| #+)/g, "");
  };
  const removeHashesFromNewLines = (str) => {
    return str.replace(/(^|\n)###/g, "");
  };

  const RegenerateCVFeedback = async (isError) => {
    let cvLinkedinUrlFeedbackObj = "";
    jobAppLang = localStorage.getItem("jobAppLang");
    try {
      if (linkdinUrl.trim() !== "") {
        var myLinkedinUrlRegEx = new RegExp(
          /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm
        );
        myLinkedinUrlRegEx = new RegExp(
          /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/
        );

        if (!myLinkedinUrlRegEx.test(linkdinUrl.trim())) {
          setLinkedinError(true);
          return;
        }
        setLinkedinError(false);

        // Linkedin FEEDBACK Request
        cvLinkedinUrlFeedbackObj = {
          JobPostUserLinkedinURL: linkdinUrl.trim(),
          UserEmail: user_email,
          JobPostURL: fetchReducer?.url,
          JobPostUserCV: "",
          JobPostUserCVName: "",
          JobPostId: fetchReducer?.jobpostId,
          Language: jobAppLang,
        };
      } else {
        //CV FEEDBACK Request
        cvLinkedinUrlFeedbackObj = {
          JobPostUserLinkedinURL: "",
          UserEmail: user_email,
          JobPostURL: fetchReducer?.url,
          JobPostUserCV: cvTxt,
          JobPostUserCVName: fileName,
          JobPostId: fetchReducer?.jobpostId,
          Language: jobAppLang,
        };
      }

      //Loading page
      setnodata(false);
      setloading(true);
      setoutput(false);
      // GET FEEDBACK RESPONSE
      const feedbackResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetUserLinkedinURL",
        cvLinkedinUrlFeedbackObj
      );
      var feedbackResponseData = feedbackResponse?.data;
      var isFeedbackError = feedbackResponseData.includes(
        "TimeOut Error: Try Again"
      );
      if (isFeedbackError) {
        setloading(false);
        setnodata(true);
        setGetError(true);
        setIsShowGenerateBtn(false);
        return;
      }
      populateCVFeedback(feedbackResponseData);
    } catch (err) {
      console.log(err.response);
      setnodata(false);
      setloading(false);
      setoutput(false);
    } finally {
      Mixpanel.track("YsfJobApp:Generate CV Feedback");
    }
  };
  const RegenerateCoverLetter = async (isError) => {
    let cvLinkedinUrlCoverLetterObj = "";
    jobAppLang = localStorage.getItem("jobAppLang");
    try {
      //COVER LETTER
      cvLinkedinUrlCoverLetterObj = {
        UserEmail: user_email,
        JobPostURL: fetchReducer?.url,
        JobPostId: fetchReducer?.jobpostId,
        Language: jobAppLang,
      };

      //Loading page
      setnodata(false);
      setloading(true);
      setoutput(false);
      setIsCoverletterloading(true);
      if (isError) {
        setGetError(false);
      }

      //COVER LETTER RESPONSE
      const coverletterResponse = await ApiConnection.post(
        "/JobbsprangetUser/JobbsprangetGenerateCoverletterApi",
        cvLinkedinUrlCoverLetterObj
      );
      //console.log("cvLinkedinUrlCoverLetterObj#"+JSON.stringify(cvLinkedinUrlCoverLetterObj));
      var coverLetterResponseData = coverletterResponse?.data?.jobPostReponse;
      var isCoverLetterError = coverLetterResponseData.includes(
        "TimeOut Error: Try Again"
      );
      isCoverLetterError = coverLetterResponseData.includes("Sure,");
      if (isCoverLetterError) {
        setnodata(true);
        setloading(false);
        setoutput(false);
        setGetError(true);
        setIsShowGenerateBtn(false);
        return;
      }
      populateCVCoverLetter(coverLetterResponseData);
    } catch (err) {
      console.log(err.response);
      setnodata(false);
      setloading(false);
      setoutput(false);
    } finally {
      Mixpanel.track("YsfJobApp:Generate CV Cover letter");
    }
  };
  const RegenerateFeedbackCoverLetter = (_activeTab) => {
    if (_activeTab.includes("cv")) {
      console.log("_activeTab#" + _activeTab);
      RegenerateCVFeedback(false);
    } else {
      console.log("_activeTab##" + _activeTab);
      RegenerateCoverLetter(false);
    }
  };

  const copyCoverLetter = (copyCoverLetter) => {
    copy(copyCoverLetter);
    toast.success("Copied to clipboard");
  };

  const onIntroChange = React.useCallback((evt) => {
    const sanitizeConf = {
      allowedTags: ["b", "i", "a", "p", "br"],
      allowedAttributes: { a: ["href"] },
    };
    let sanitizedContent;
    sanitizedContent = evt.currentTarget.innerHTML.replace(/\n/g, "<br>");
    setintrotext(sanitizeHtml(sanitizedContent, sanitizeConf));
  }, []);

  const onBodyChange = React.useCallback((evt) => {
    const sanitizeConf = {
      allowedTags: ["b", "i", "a", "p", "br"],
      allowedAttributes: { a: ["href"] },
    };
    let sanitizedContent;
    sanitizedContent = evt.currentTarget.innerHTML.replace(/\n/g, "<br>");
    setbodytext(sanitizeHtml(sanitizedContent, sanitizeConf));
  }, []);

  const onConclusionChange = React.useCallback((evt) => {
    const sanitizeConf = {
      allowedTags: ["b", "i", "a", "p", "br"],
      allowedAttributes: { a: ["href"] },
    };
    let sanitizedContent;
    sanitizedContent = evt.currentTarget.innerHTML.replace(/\n/g, "<br>");
    setConclusionTxt(sanitizeHtml(sanitizedContent, sanitizeConf));
  }, []);

  // Missing Keywords
  const populateMissingKeywords = async (missingKeiwordsResponse, jobId) => {
    let mArray = [];
    let _mRArray = [];
    if (missingKeiwordsResponse) {
      console.log("Missing#" + missingKeiwordsResponse?.data);
      if (
        (missingKeiwordsResponse?.data).includes("Please") ||
        (missingKeiwordsResponse?.data).includes("Sure") ||
        (missingKeiwordsResponse?.data).includes("I'm sorry,")
      ) {
        setcurrentKewords(mArray);
        return;
      }
      //var splitData = missingKeiwordsResponse?.data.split("-");
      var splitData = missingKeiwordsResponse?.data.split("\n");
      let mSaveTxt = "";
      splitData &&
        splitData.forEach((element, index) => {
          let mTxt = element.trim();
          mTxt = mTxt.replace(/-/g, "");
          //mTxt = mTxt.replace(/^\d+/, "");
          mTxt = mTxt.replace(/\b\d+\./g, "");
          if (mTxt.length > 0) {
            mArray.push(mTxt.trim());
            mSaveTxt += mTxt.trim() + "|";
          }
        });
      //splitData.shift();
      console.log("mArray", mArray);
      if (mArray.length > 0) {
        setcurrentKewords(mArray);
        console.log("mSaveTxt#", mSaveTxt);
        if (mSaveTxt.toString().endsWith("|")) mSaveTxt.toString().slice(0, -1);
        let _mSaveTxt = mSaveTxt.toString().replace(/|\s*$/, "");
        console.log("mSaveTxt##", _mSaveTxt);
        saveMissingKeywords(_mSaveTxt, "");
        setcompletedKeywords(_mRArray);
      } else {
        setcurrentKewords(mArray);
        setcompletedKeywords(_mRArray);
      }
    }
  };
  const saveMissingKeywords = async (mSaveTxt, mSaveReviewTxt) => {
    let saveMissingKeywordObj = {
      UserEmail: user_email,
      JobPostId: fetchReducer?.jobpostId,
      JobPostMissingSkill: mSaveTxt,
      JobPostReviewedSkill: mSaveReviewTxt,
    };
    await ApiConnection.post(
      "/JobbsprangetUser/JobbsprangetSaveMissingSkillApi",
      saveMissingKeywordObj
    );
  };

  const handleDragStart = (task, source) => {
    const dragData = { task, source };
    window.localStorage.setItem("draggedTask", JSON.stringify(dragData));
    setdefaultdrop(false);
    if (source === "current") {
      setrighttoleftdrop(false);
      setlefttorightdrop(true);
    } else {
      setlefttorightdrop(false);
      setrighttoleftdrop(true);
    }
  };

  const handleDrop = (target) => {
    const dragData = JSON.parse(window.localStorage.getItem("draggedTask"));
    if (!dragData) return;
    const { task, source } = dragData;
    if (source === "current" && target === "completed") {
      setcurrentKewords((prev) => prev.filter((t) => t !== task));
      setcompletedKeywords((prev) => [...prev, task]);

      var currentData = [...currentKewords];
      var filtercurrentData = currentData.filter((current) => current !== task);
      var filterCompleteData = [...completedKeywords, task];

      //console.log("lefttorightcurrentKewords#" + filtercurrentData);
      //console.log("lefttorightcompletedKeywords#" + filterCompleteData);

      //console.log("LTORCR#" + filtercurrentData);
      //console.log("LTORCMO#" + filterCompleteData);
      let saveMissing = "";
      let saveReviewed = "";
      if (filtercurrentData) {
        saveMissing = filtercurrentData.toString().replace(/,/g, "|");
        console.log("saveMis#" + saveMissing);
      }
      if (filterCompleteData) {
        saveReviewed = filterCompleteData.toString().replace(/,/g, "|");
        //console.log("saveReviewed#" + saveReviewed);
      }
      saveMissingKeywords(saveMissing, saveReviewed);
    } else if (source === "completed" && target === "current") {
      setcompletedKeywords((prev) => prev.filter((t) => t !== task));
      setcurrentKewords((prev) => [...prev, task]);

      var currentDataforapi = [...currentKewords, task];
      var compleDataforApi = completedKeywords.filter(
        (complete) => complete !== task
      );

      //console.log("RighttoLeftcurrentDataforapi" + currentDataforapi);
      //console.log("RighttoLeftcompleDataforApi" + compleDataforApi);
      let _saveMissing = "";
      let _saveReviewed = "";
      if (currentDataforapi) {
        _saveMissing = currentDataforapi.toString().replace(/,/g, "|");
        //console.log("saveMis#" + _saveMissing);
      }
      if (compleDataforApi) {
        _saveReviewed = compleDataforApi.toString().replace(/,/g, "|");
        //console.log("saveReviewed#" + _saveReviewed);
      }
      saveMissingKeywords(_saveMissing, _saveReviewed);
    }
    window.localStorage.removeItem("draggedTask");
    if (target === "completed") {
      setlefttorightdrop(false);
      setdefaultdrop(true);
    } else {
      setrighttoleftdrop(false);
      setdefaultdrop(true);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Replace CV
  const ReplaceCVHnd = async (e) => {
    console.log(e.target.files[0]);
    var file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setregenerateloader(true);

      try {
        let data = new FormData();
        data.append("files", file);
        const response = await FormdataApi.post(
          "/JobbsprangetFileUploadUser/ReadDocFile",
          data
        );
        if (response) {
          console.log("saveMisRegenerate#" + response);
          setregenerateloader(false);
          setCVTxt(response.data);
          BtnGenerateFeedbackHnd(false, response.data, file.name);
        }
      } catch (err) {}
    }
  };

  const BackToJobDescription = () => {
    dispatch(StepAction(1));
    navigate("/Update-the-CV", {
      state: { id: fetchReducer?.jobpostId, jobPost: "getJobpost" },
    });
  };

  // END
  return (
    <>
      <div className="row now-gutters vh-class">
        <div className="col-lg-6">
          <div className="common-left">
            <div className="pageHeading">
              <h1>
                <img src={IMAGE.Document} alt="" />
                {feedback ||
                fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
                fetchReducer.goToConceren === "CVFeedbackGenerated"
                  ? "My Profile"
                  : "Interview"}
              </h1>
            </div>
            {!iskeywordShow ? (
              <div className="default-card">
                <div className="default-card-header">
                  <span>1</span>
                  <div className="default-card-header-text">
                    <h3>
                      {" "}
                      {feedback ||
                      fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
                      fetchReducer.goToConceren === "CVFeedbackGenerated"
                        ? "Analyse and finalise your CV!"
                        : "Prepare for your interview"}
                    </h3>
                    <p>
                      {feedback ||
                      fetchReducer.goToConceren === "CVFeedbackNotGenerated" ||
                      fetchReducer.goToConceren === "CVFeedbackGenerated"
                        ? "Let’s start by uploading your CV or pasting your LinkedIn profile."
                        : "Start the session by uploading CV or pasting your linkedin profile."}
                    </p>
                  </div>
                  <div className="default-card-header-img">
                    <img src={IMAGE.context_card_img} alt="" />
                  </div>
                </div>
                <div className="default-card-body">
                  <div
                    className="input-field-list"
                    style={{
                      opacity: isactivecvfield ? 1 : 0.4,
                      pointerEvents: isactivecvfield ? "auto" : "none",
                    }}
                  >
                    <div className="input-field-label">
                      <span>
                        <img src={IMAGE.qa_icon} alt="" />
                      </span>
                      <div className="input-field-info">
                        <h4>Click to upload your CV</h4>
                        <p>You can also drop your file in this area.</p>
                      </div>
                    </div>
                    <div className="input-field">
                      {isfile === "" ? (
                        <FileUploader
                          classes="custom-drop"
                          children={
                            <div className="fileupload-option">
                              <span>
                                <img src={IMAGE.upload_icon} alt="" />
                              </span>
                              <p>Click or drop the file upload it</p>
                            </div>
                          }
                          handleChange={CVUploadHandleChange}
                          onDrop={(file) => setisfile(file)}
                          onSelect={(file) => setisfile(file)}
                          name="file"
                          types={fileTypes}
                        />
                      ) : (
                        <>
                          <div className="cv-output">
                            {uploadLoader ? (
                              <div className="uploadLoader">
                                <h4>
                                  <img
                                    src={IMAGE.loader_icon}
                                    className="LoaderIcon"
                                    alt=""
                                  />{" "}
                                  {file.name}
                                </h4>
                                <button onClick={CancelUploadHandle}>
                                  <img src={IMAGE.Upload_cross} alt="" />
                                </button>
                              </div>
                            ) : (
                              <>
                                <div className="success-upload">
                                  <h4>
                                    <img src={IMAGE.Attachment} alt="" />{" "}
                                    {fileName}
                                  </h4>
                                  <div>
                                    <button
                                      className="replaceCVBtn"
                                      onClick={DeleteHandle}
                                    >
                                      <img src={IMAGE.Refresh_icon} alt="" />{" "}
                                      Replace CV
                                    </button>
                                    <button
                                      onClick={DeleteHandle}
                                      className="DeleteBrn"
                                    >
                                      <img src={IMAGE.Delete} alt="" />
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="or-sep">
                    <span>or</span>
                  </div>
                  <div
                    className="input-field-list"
                    style={{
                      opacity: isactiveurlfield ? 1 : 0.4,
                      pointerEvents: isfile === "" ? "auto" : "none",
                    }}
                  >
                    <div className="input-field-label">
                      <span>
                        <img src={IMAGE.qa_icon} alt="" />
                      </span>
                      <div className="input-field-info">
                        <h4>Insert your LinkedIn URL</h4>
                        <p>
                          You can paste your audience’s LinkedIn URL directly in
                          here:
                        </p>
                      </div>
                    </div>

                    <div className="input-field">
                      <div className="linkdinUrl">
                        <input
                          type="url"
                          className="form-control"
                          placeholder="Insert your LinkedIn URL here"
                          value={linkdinUrl}
                          onChange={(e) =>
                            linkdinUrlHandleInput(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {goToConcern === 1 && (
                    <div className="text-end">
                      {linkedinError && (
                        <>
                          <h5 className="wrongposturltext">
                            Please enter a valid Linkedin URL!{" "}
                          </h5>
                          <br></br>
                        </>
                      )}
                      {isShowGenerateBtn && (
                        <button
                          className="themeBtn hover-btn generatefeedbackBttn"
                          onClick={() => BtnGenerateFeedbackHnd(false)}
                          disabled={loading ? true : false}
                        >
                          Generate Feedback
                        </button>
                      )}
                    </div>
                  )}
                  {getError && (
                    <div className="noresultFound">
                      <img src={IMAGE.alert_icon} alt="" />
                      <div className="noresultFoundInner">
                        <h4>Results not generated</h4>
                        <p>
                          Oops!!! <br />
                          Looks like our servers are having a busy day.
                          <br /> Please try to generate your results again.
                        </p>
                      </div>
                      <button
                        className="tryBtn"
                        onClick={() => BtnGenerateFeedbackHnd(true)}
                        disabled={loading ? true : false}
                      >
                        Try Again
                      </button>
                    </div>
                  )}

                  {goToConcern === 2 && (
                    <div className="text-end">
                      {isShowGenerateBtn && (
                        <button
                          className="themeBtn hover-btn"
                          onClick={BtnGoToConcernHnd}
                          disabled={loading ? true : false}
                        >
                          Generate Concerns & Gains
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="keyword-card">
                <div className="cv-output">
                  <div className="success-upload">
                    <h4>
                      <img src={IMAGE.Attachment} alt="" /> {fileName}
                    </h4>

                    <div>
                      <div className="upload-file">
                        {regenerateloader ? (
                          <span className="loaderBttn">
                            <img
                              src={IMAGE.loader_icon}
                              className="LoaderIcon"
                              alt=""
                            />{" "}
                            Loading
                          </span>
                        ) : (
                          <>
                            <label>
                              <img src={IMAGE.Refresh_icon} alt="" /> Regenerate
                            </label>
                            <input
                              type="file"
                              accept="application/pdf, application/doc, application/docx"
                              onChange={ReplaceCVHnd}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="keyword-outbox">
                  <p>
                    On the left, you’ll see keywords that are missing from your
                    CV. Feel free to drag any keyword to the right side once
                    you’ve reviewed, added, or decided to ignore it. This helps
                    you keep track of your choices!
                  </p>

                  <div className="draganddrop">
                    <div
                      className={
                        righttoleftdrop ? "drag-left hover" : "drag-left"
                      }
                      onDragOver={handleDragOver}
                      onDrop={() => handleDrop("current")}
                    >
                      <div className="draganddrop-heading">
                        <span>Missing Keywords</span>
                      </div>
                      <div className="drag-inner">
                        {currentKewords?.length > 0 ? (
                          currentKewords.map((keyword) => (
                            <div
                              className="drag-key"
                              key={keyword}
                              draggable
                              onDragStart={() =>
                                handleDragStart(keyword, "current")
                              }
                            >
                              {keyword}
                            </div>
                          ))
                        ) : (
                          <div className="no-missing-output">
                            <img src={IMAGE.nomissingkeywords} alt="" />
                            <p>
                              Your CV seems to include all the essential
                              keywords. Great work!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="drag-direction">
                      {lefttorightdrop && (
                        <img src={IMAGE.right_direction} alt="" />
                      )}
                      {righttoleftdrop && (
                        <img src={IMAGE.left_direction} alt="" />
                      )}

                      {defaultdrop && <img src={IMAGE.both_direction} alt="" />}
                    </div>
                    <div
                      className={
                        lefttorightdrop ? "drag-right hover" : "drag-right"
                      }
                      onDragOver={handleDragOver}
                      onDrop={() => handleDrop("completed")}
                    >
                      <div className="draganddrop-heading">
                        <span>Reviewed Keywords</span>
                      </div>
                      <div className="drag-inner">
                        {completedKeywords?.length > 0 ? (
                          <>
                            {completedKeywords.map((task) => (
                              <div
                                className="drop-key"
                                key={task}
                                draggable
                                onDragStart={() =>
                                  handleDragStart(task, "completed")
                                }
                              >
                                {task}
                              </div>
                            ))}

                            {/* {completedKeywords?.length === 0 && (
                            <div className="droptext">
                              <img src={IMAGE.plus_icon} alt="plus-icon" />
                              <h5>Drop any keyword here</h5>
                            </div>
                          )} */}
                          </>
                        ) : (
                          // <div className="no-missing-output">
                          //   <img src={IMAGE.reviewedkeyword} alt="" />
                          //   <p>
                          //     No missing keywords to drag? Let’s review the right
                          //     panel for even more insights!
                          //   </p>
                          // </div>
                          <div className="droptext">
                            <img src={IMAGE.plus_icon} alt="plus-icon" />
                            <h5>Drop any keyword here</h5>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-6 p-0">
          <div className={output ? "comonn-right output" : "comonn-right"}>
            {loading && <ConcernLoader />}
            {output && (
              <div className="output-view mt-5 job-profile">
                <ul className="concern-container-ouput-tabs">
                  <li>
                    <button
                      className={
                        activeTab === "cv" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setactiveTab("cv")}
                    >
                      <img src={IMAGE.Supergroup} alt="" /> CV Feedback
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        activeTab === "cover" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setactiveTab("cover")}
                    >
                      <img src={IMAGE.Message_Align_Right} alt="" /> Cover
                      letter
                    </button>
                  </li>
                  <li>
                    <button
                      className="regenrateBttn"
                      onClick={() => RegenerateFeedbackCoverLetter(activeTab)}
                    >
                      <img
                        src={IMAGE.regenerated_icon}
                        alt="icon"
                        title="Regenarate"
                      />
                    </button>
                  </li>
                </ul>
                <div>
                  {activeTab === "cv" ? (
                    <>
                      <div className="output-view-body p-0 step-oneview">
                        <h4 className="special_heading">
                          {" "}
                          <img src={IMAGE.notes_checkicon} alt="" /> Please
                          review the suggestions, update your CV, and re-submit
                          it to see how much better it aligns!
                        </h4>
                        <ul id="ListedBox">
                          {list &&
                            list.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    item.toggleSelected ? "selected" : ""
                                  }
                                >
                                  {/* <label onClick={() => SelectHandle(item)}> {item.text}</label>    */}
                                  <span onClick={() => SelectHandle(item)}>
                                    {item.toggleSelected ? (
                                      <img src={IMAGE.check_icon_one} alt="" />
                                    ) : (
                                      <img src={IMAGE.uncheck_icon} alt="" />
                                    )}
                                  </span>
                                  <label> {item.text}</label>
                                  <button className="crosBtn">
                                    <img
                                      src={IMAGE.x_button}
                                      alt=""
                                      onClick={() => ModalShow(item.id)}
                                    />
                                  </button>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      {/* <div className="text-end mt-3">                       
                        <button
                          className="coverlettrBtn hover-btn"
                          onClick={ToCoverLetter}
                        >
                          To Cover Letter
                        </button>
                      </div> */}
                      <div className="float-bttn">
                        <button
                          value={activeTab}
                          onClick={() =>
                            RegenerateFeedbackCoverLetter(activeTab)
                          }
                        >
                          <img src={IMAGE.generate_icon} alt="" /> Regenerate
                        </button>
                        <button onClick={ToCoverLetter}>
                          To Cover Letter <img src={IMAGE.right_arrow} alt="" />
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="cover-letter">
                        <h4 className="special_heading">
                          {" "}
                          <img src={IMAGE.edit_note} alt="" /> Get a head start
                          with this personalized cover letter. Feel free to
                          paste anywhere you want!
                        </h4>
                        <div className="cover-letter-sep">
                          <h4>
                            Introduction{" "}
                            <button onClick={() => copyCoverLetter(introtext)}>
                              <img src={IMAGE.copy_icon} alt="" />
                            </button>
                            <button
                              className="editBttn"
                              onClick={() => IntroeditTextRef.current.focus()}
                            >
                              Edit
                            </button>
                          </h4>
                          {isCoverletterloading && (
                            <Loader title="Job cover letter!!" />
                          )}
                          {!isCoverletterloading && (
                            <ContentEditable
                              style={{ whiteSpace: "pre-wrap" }}
                              onChange={onIntroChange}
                              html={introtext}
                              innerRef={IntroeditTextRef}
                            />
                          )}
                        </div>
                        <div className="cover-letter-sep">
                          <h4>
                            Body Content{" "}
                            <button onClick={() => copyCoverLetter(bodytext)}>
                              <img src={IMAGE.copy_icon} alt="" />
                            </button>
                            <button
                              className="editBttn"
                              onClick={() => BodyeditTextRef.current.focus()}
                            >
                              Edit
                            </button>
                          </h4>

                          {isCoverletterloading && (
                            <Loader title="Job cover letter!!" />
                          )}
                          {!isCoverletterloading && (
                            <ContentEditable
                              style={{ whiteSpace: "pre-wrap" }}
                              onChange={onBodyChange}
                              html={bodytext}
                              innerRef={BodyeditTextRef}
                            />
                          )}
                          {/* <pre>{bodytext}</pre> */}
                        </div>
                        <div className="cover-letter-sep">
                          <h4>
                            Conclusion{" "}
                            <button
                              onClick={() => copyCoverLetter(conclusionTxt)}
                            >
                              <img src={IMAGE.copy_icon} alt="" />
                            </button>
                            <button
                              className="editBttn"
                              onClick={() =>
                                ConclusioneditTextRef.current.focus()
                              }
                            >
                              Edit
                            </button>
                          </h4>
                          {isCoverletterloading && (
                            <Loader title="Job cover letter!!" />
                          )}
                          {!isCoverletterloading && (
                            <ContentEditable
                              style={{ whiteSpace: "pre-wrap" }}
                              onChange={onConclusionChange}
                              html={conclusionTxt}
                              innerRef={ConclusioneditTextRef}
                            />
                          )}
                        </div>
                      </div>

                      <div className="float-bttn">
                        {!isShowSaveFinishBtn && (
                          <>
                            <button
                              value={activeTab}
                              onClick={() =>
                                RegenerateFeedbackCoverLetter(activeTab)
                              }
                            >
                              <img src={IMAGE.generate_icon} alt="" />{" "}
                              Regenerate
                            </button>

                            <button onClick={() => NextAction(1)}>
                              Finish
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {nodata && (
              <Nodata
                image={IMAGE.YSF_generate_icon}
                text="This is where you find your CV feedback and improvement suggestions "
              />
            )}
          </div>
        </div>
      </div>
      {goToConcern === 1 && (
        <div className="concern-footer">
          <button onClick={() => BackToJobDescription()}>
            <i className="fa-solid fa-arrow-left"></i>Back
          </button>
        </div>
      )}

      <Modal
        show={showmodal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="deletemodal p-5">
          <div className="row align-items-center">
            <div className="col-lg-6 text-center">
              <img src={IMAGE.del_img} alt="" />
            </div>
            <div className="col-lg-6 text-center">
              <h3>Are you sure?</h3>
              <h6>
                You’re removing the suggestion. <br></br> This can't be
                recovered.
              </h6>
              <ul>
                <li>
                  <button
                    className="borderBtn"
                    onClick={() => setshowmodal(false)}
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button className="solidBtn" onClick={() => DlistHandle()}>
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JobProfile;
